@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

@font-face {
	font-family: 'NeueHaasGroteskText Std Md';
	src: url('../../dist/font/NHaasGroteskTXStd-65Md.woff2') format('woff2'),
			url('../../dist/font/NHaasGroteskTXStd-65Md.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: 'NeueHaasGroteskText Std Md', Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	position: relative;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.bg{
	position: fixed;
	width: 100%;
	height: 100%;
	object-fit: cover;
}


.btn{
	background-color: $black;
	color: $white;
	font-size: 16px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 700;
	padding: 10px 30px;
	display: inline-block;
	@include tablet-up{
		font-size: 18px;
	}
	@include hover-focus{
		background-color: $gray;
	}
}

.mobile{
	display: block;
	@include tablet-up{
		display: none;
	}
}

.desktop{
	display: block;
	@include tablet-down{
		display: none;
	}
}

header {
	position: relative;
	padding-top: 40px;
	z-index: 99;
	.social{
		@include flex(row,flex-end,center);
		gap: 10px;
		width: 345px;
		margin: 0 auto -60px;
		a{
			font-size: 20px;
			color: $black;
			@include hover-focus{
				color: $gray;
			}
		}
	}
	@include tablet-up{
		padding: 60px 0;
		.social{
			width: 100%;
			margin: 0;
			gap: 30px;
			a{
				font-size: 30px;
			}
		}
	}
}

main {
	position: relative;
	width: 100%;

	.logo.mobile{
		margin: 0 auto;
		max-width: 345px;
	}

	#events{
		display: grid;
		gap: 50px;
		.event{
			font-weight: 700;
			font-size: 16px;
			text-transform: uppercase;
			@include flex(column,center,center);
			gap: 10px;
			>div{
				flex: 1;
			}
			.event-location{
				span{
					position: relative;
					&::after{
						position: absolute;
						top: 0;
						right: -12px;
					}
				}
			}
			&:nth-child(-n + 4){
				.event-location{
					span{
						&::after{
							content: "*";
							font-weight: 700;
							color: $gray;
							font-size: 20px;
						}
					}
				}
			}
			&:nth-child(n + 5){
				.event-location{
					span{
						&::after{
							content: "";
							display: block;
							width: 8px;
							height: 8px;
							border-radius: 8px;
							border: solid 2px $black;
							box-sizing: border-box;
						}
					}
				}
			}
		}
	}

	@include tablet-up{
		.container{
			@include flex(row,space-between,flex-start);
			.wrapper{
				max-width: 700px;
				flex: 1;
				.logo{
					margin-bottom: 100px;
				}
				#events{
					.event{
						flex-direction: row;
						justify-content: space-between;
						gap: 20px;
						font-size: 18px;
						.event-date{
							max-width: max-content;
						}
						.event-location{
							color: $black;							
						}
						.event-links{
							max-width: max-content;
						}
					}
				}
			}
			.artist.desktop{
				display: block;
				margin-top: -120px;
				max-width: 450px;
				flex: 1;
			}
		}
	}
}

footer {
	position: relative;
	width: 100%;
	padding: 80px 0 20px;
	.copyright{
		@include flex(column,center,center);
		gap: 20px;
		max-width: 800px;
		margin: 0 auto;
		img{
			width:60px;
		}
		p{
			line-height: 1.6;
			text-align: center;
			color: $gray;
			font-size: 12px;
			a{
				color: $gray;
				@include hover-focus{
					color: $white;
				}
			}
		}
	}
	@include tablet-up{
		padding-top: 0;
		.copyright{
			flex-direction: row;
			justify-content: flex-start;
			p{
				text-align: left;
			}
		}
	}
}
/* Images */
img {

	&.size-auto,
	&.size-full,
	&.size-large,
	&.size-medium {
		height: auto;
		max-width: 100%;
	}

	&.alignleft {
		display: inline;
		float: left;
		margin-bottom: rem(15);
		margin-right: rem(15);
	}

	&.alignright {
		display: inline;
		float: right;
		margin-bottom: rem(15);
		margin-left: rem(15);
	}

	&.aligncenter {
		clear: both;
		display: block;
		margin-bottom: rem(15);
		margin-left: auto;
		margin-right: auto;
	}
}

.attachment img {
	height: auto;
	max-width: 100%;
}

.alignleft {
	display: inline;
	float: left;
	margin-right: rem(15);
}

.alignright {
	display: inline;
	float: right;
	margin-left: rem(15);
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}